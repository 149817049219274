<template>
  <div class="container" >
    <div class="container" style="height: 100px;">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h1>Users</h1>
      </div>
      <div class="col-md-6">
        <button @click="moveToAddUser" class="plus-button">
  <span class="plus-icon">+</span>
</button>
      </div>
    </div>
  </div>
    <table class="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.id }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <button
              class="delete-button"
              @click="deleteUser(user.id, user.status)"
              :disabled="isDeleting"
            >
              {{
                isDeleting && deletingId === user.id
                  ? "Blocking..."
                  : user.status === 0
                  ? "Unblock"
                  : "Block"
              }}
            </button>
            <p>   </p>
            <button
              class="changepass-button"
              @click="updatePassword(user.id,)"
             
            >
            <router-link :to="{ name: 'change-password', params: { userId: user.id } }">Change Password</router-link>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      users: [],
      isDeleting: false,
      deletingId: null,
    };
  },

  mounted() {
    this.fetchUsers();
  },

  methods: {
    async fetchUsers() {
      try {
        const response = await fetch("https://staging.wardah.dev/api/users");
        const data = await response.json();
        this.users = data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteUser(userId, status) {
      if (confirm("Are you sure you want to do this action?")) {
        this.isDeleting = true;
        this.deletingId = userId;
        try {
          if (status === 0) {
            const response = await axios.post(
              `https://staging.wardah.dev/api/users/block`,
              {
                id: userId,
                block: 1,
              }
            );
            if (response.status === 200) {
              this.fetchUsers();
            }
          } else {
            const response = await axios.post(
              `https://staging.wardah.dev/api/users/block`,
              {
                id: userId,
                block: 0,
              }
            );
            if (response.status === 200) {
              this.fetchUsers();
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isDeleting = false;
          this.deletingId = null;
        }
      }
    },
    async updatePassword(userId) {
      console.log(userId);
    },
    moveToAddUser(){
      this.$router.push("/add");
    }
  },
};
</script>
<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.delete-button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.changepass-button {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #d32f2f;
}

.plus-button {
  background-color: #000000;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.plus-icon {
  font-size: 20px;
  font-weight: bold;
}

</style>
