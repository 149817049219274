<template>
  <div class="container">
    <form @submit.prevent="login">
      <h2 class="mb-3">Admin Login</h2>
      <div class="input">
        <label for="email">Username</label>
        <input
          class="form-control"
          type="text"
          name="email"
          placeholder="username"
        />
      </div>
      <div class="input">
        <label for="password">Password</label>
        <input
          class="form-control"
          type="password"
          name="password"
          placeholder="password123"
        />
      </div>
      <!-- <div class="alternative-option mt-4">
        You don't have an account? <span @click="moveToRegister">Register</span> -->
      <!-- </div> -->
      <button type="submit" class="mt-4 btn-pers" id="login_button">
        Login
      </button>
      <div
        class="alert alert-warning alert-dismissible fade show mt-5 d-none"
        role="alert"
        id="alert_1"
      >
        Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login(submitEvent) {
      this.email = submitEvent.target.elements.email.value;
      this.password = submitEvent.target.elements.password.value;
      try {
        axios
          .post("https://staging.wardah.dev/api/admin/signin", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            // this.user = response.data.message.data;
            let alert_1 = document.querySelector("#alert_1");
            alert_1.classList.remove("d-none");
            alert_1.innerHTML = response.data.success;
            this.$router.push("/dashboard");
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("user", JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
            let alert_1 = document.querySelector("#alert_1");
            alert_1.classList.remove("d-none");
            alert_1.innerHTML = error;
          });
      } catch (e) {
        let alert_1 = document.querySelector("#alert_1");
        alert_1.classList.remove("d-none");
        alert_1.innerHTML = e;
      }
    },
  },
};
</script>
