import { createRouter, createWebHistory } from "vue-router";

import LoginView from "../views/LoginView.vue";
import DashBoardView from "../views/DashboardView.vue"

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/DashboardView.vue"),
    component: DashBoardView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/add",
    name: "add",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddUser.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/change-password/:userId',
    name: 'change-password',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChangePassword.vue"),
    props: true,
    meta: {
      authRequired: true,
    },
  }

  
];

const router =  new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const user = JSON.parse(localStorage.getItem("user"));
    if (isLoggedIn && user) {
      //next();
      next();
    } else {
      alert("You've must been logged to access this area");
      router.push("/");
    }
    // if (auth.currentUser) {
    //   next();
    // } else {
    //   alert("You've must been logged to access this area");
    //   router.push("/");
    // }
  } else {
    next();
  }
});

export default router;
